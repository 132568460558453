import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/animate.css/animate.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css";
import "./i18n";
import App from "./App";
import { HelmetProvider } from "react-helmet-async";

ReactDOM.render(
  <React.StrictMode>
      <HelmetProvider>
          <Suspense fallback={""}>
            <App />
        </Suspense>
      </HelmetProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
