import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useOutletContext,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { gsap } from "gsap";
import { LocaleProvider } from "./components/General/LocaleContext";
import { NavThemeProvider } from "./components/General/NavThemeContext";
import ComponentLoader from "./components/ComponentLoader/ComponentLoader";

import "react-lazy-load-image-component/src/effects/blur.css";
import Jobs from "./components/Job/Jobs";

const Layout = React.lazy(() => import("./components/General/Layout"));
const Landing = React.lazy(() => import("./components/Landing/Landing"));
const Competences = React.lazy(() =>
  import("./components/Competences/Competences")
);
const Competence = React.lazy(() =>
  import("./components/Competences/Competence")
);
const CompetencesOverview = React.lazy(() =>
  import("./components/Competences/CompetencesOverview")
);
const Activities = React.lazy(() =>
  import("./components/Activities/Activities")
);
const Activity = React.lazy(() => import("./components/Activities/Activity"));
const ActivitiesOverview = React.lazy(() =>
  import("./components/Activities/ActivitiesOverview")
);
const Lawyer = React.lazy(() => import("./components/Lawyers/Lawyer"));
const Lawyers = React.lazy(() => import("./components/Lawyers/Lawyers"));
const LawFirm = React.lazy(() => import("./components/LawFirm/LawFirm"));
const LawyersOverview = React.lazy(() =>
  import("./components/Lawyers/LawyersOverview")
);
const Imprint = React.lazy(() => import("./components/General/Imprint"));
const DataPrivacy = React.lazy(() =>
  import("./components/General/DataPrivacy")
);
const NotFound = React.lazy(() => import("./components/General/NotFound"));
const Job = React.lazy(() => import("./components/Job/Job"));
const JobsOverview = React.lazy(() => import("./components/Job/JobsOverview"));
const Blog = React.lazy(() => import("./components/Blog/pages/Blog"));
const BlogOverview = React.lazy(() =>
  import("./components/Blog/pages/BlogOverview")
);
const Article = React.lazy(() => import("./components/Blog/pages/Article"));

const { REACT_APP_STRAPI_API_URL } = process.env;

// apollo client
const client = new ApolloClient({
  uri: `${REACT_APP_STRAPI_API_URL}/graphql`,
  cache: new InMemoryCache(),
});

function App() {
  const { t, i18n } = useTranslation("routes");
  gsap.config({ nullTargetWarn: false });

  // get language substring from value returned by the browser language detector, e.g. de-DE -> de
  if (i18n.language.length > 2) {
    let languageSubString = i18n.language.substring(0, 2);
    i18n.changeLanguage(languageSubString);
  }

  return (
    <Suspense fallback={<ComponentLoader />}>
      <LocaleProvider value={null}>
        <ApolloProvider client={client}>
          <NavThemeProvider value={{ navTheme: "white" }}>
            <Router>
              <Routes>
                <Route path="/" element={<Layout />}>
                  <Route
                    path="/"
                    element={
                      <Navigate replace to={"/" + i18n.language + "/"} />
                    }
                  />
                  <Route path=":locale" context={useOutletContext()}>
                    <Route index element={<Landing />} />
                    <Route
                      path={t("routes:competences")}
                      element={<Competences />}
                    >
                      <Route
                        path={t("routes:overview")}
                        element={<CompetencesOverview />}
                      />
                      <Route path=":slug" element={<Competence />} />
                    </Route>
                    <Route
                      path={t("routes:activities")}
                      element={<Activities />}
                    >
                      <Route
                        path={t("routes:overview")}
                        element={<ActivitiesOverview />}
                      />
                      <Route path=":slug" element={<Activity />} />
                    </Route>
                    <Route path={t("routes:lawyers")} element={<Lawyers />}>
                      <Route
                        path={t("routes:overview")}
                        element={<LawyersOverview />}
                      />
                      <Route path=":slug" element={<Lawyer />} />
                    </Route>
                    <Route path={t("routes:lawfirm")} element={<LawFirm />} />
                    <Route path={t("routes:imprint")} element={<Imprint />} />
                    <Route
                      path={t("routes:dataprivacy")}
                      element={<DataPrivacy />}
                    />
                    <Route path={t("routes:career")} element={<Jobs />} >
                      <Route
                          path={t("routes:overview")}
                          element={<JobsOverview />}
                      />
                      <Route path=":slug" element={<Job />} />
                    </Route>
                    <Route path={t("routes:blog")} element={<Blog />}>
                      <Route
                        path={t("routes:overview")}
                        element={<BlogOverview />}
                      />
                      <Route path=":slug" element={<Article />} />
                    </Route>
                    <Route
                      path="*"
                      element={
                        <Navigate replace to={"/" + i18n.language + "/"} />
                      }
                    />
                  </Route>
                </Route>
                <Route path="*" element={<NotFound useMeta={true} />} />
              </Routes>
            </Router>
          </NavThemeProvider>
        </ApolloProvider>
      </LocaleProvider>
    </Suspense>
  );
}

export default App;
