import React, { useState, useContext } from 'react'

const NavThemeContext = React.createContext();
const UpdateNavThemeContext  = React.createContext();
const UpdateHeaderGradientContext  = React.createContext();

export const useNavTheme = () => {
    return useContext(NavThemeContext);
}

export const useUpdateNavThemeContext = () => {
    return useContext(UpdateNavThemeContext);
}

export const useUpdateHeaderGradientContext = () => {
    return useContext(UpdateHeaderGradientContext);
}

export const NavThemeProvider = ({ value, children }) => {
    const [ darkNavTheme, setDarkNavTheme ] = useState(value);
    const [ headerGradient, setHeaderGradient] = useState("rgba(0, 0, 0, 0)");

    return (
        <NavThemeContext.Provider value={{navTheme: darkNavTheme, headerGradient}}>
            <UpdateNavThemeContext.Provider value={setDarkNavTheme}>
                <UpdateHeaderGradientContext.Provider value={setHeaderGradient}>
                    {children}
                </UpdateHeaderGradientContext.Provider>
            </UpdateNavThemeContext.Provider>
        </NavThemeContext.Provider>
    );
}

