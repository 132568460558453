import React, { useState, useContext } from 'react'

const LocaleContext = React.createContext();
const UpdateLocaleContext = React.createContext();

export const useLocale = () => {
    return useContext(LocaleContext);
}

export const useUpdateLocaleContext = () => {
    return useContext(UpdateLocaleContext);
}

export const LocaleProvider = ({ value, children }) => {
    const [ otherLocaleSlug, setOtherLocaleSlug ] = useState(value);

    return (
        <LocaleContext.Provider value={otherLocaleSlug}>
            <UpdateLocaleContext.Provider value={setOtherLocaleSlug}>
                {children}
            </UpdateLocaleContext.Provider>
        </LocaleContext.Provider>
    );
}

