import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        whitelist: ['de', 'en'],
        preload: ['de', 'en'],
        load: 'languageOnly',
        supportedLngs: ['de', 'en'],
        fallbackLng: 'en',
        detection: {
            checkWhitelist: true,
            order: ['path', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'subdomain'],
            lookupFromPathIndex: 0
        },
        debug: false,
        returnEmptyString: true,
        interpolation: {
            escapeValue: false
        },
        react: {
            useSuspense: true
        }
    });


export default i18n;