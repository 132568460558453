import React from 'react'
import styled from "styled-components";

const ComponentLoader = ({ color = "#8B8888", size = "40px" }) => (
  <Container color={color} size={size}>
    <div className="loader-block">
      <span className="loader-element" />
    </div>
  </Container>
);

export default React.memo(ComponentLoader);

const Container = styled.section`
  .loader {
    &-block {
      height: 50vh;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-element {
      position: relative;
      width: ${(props) => props?.size};
      height: ${(props) => props?.size};
      border: 1px solid ${(props) => props?.color};
      border-bottom-color: transparent;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: loader-rotate 1.2s linear infinite;
    }
  }

  @keyframes loader-rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;
